body {
  margin: 0;
font-family: 'Heebo', sans-serif;
font-family: 'Secular One', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
